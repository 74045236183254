<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 10:10:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 16:22:36
-->

<template>
  <el-container class="page">
    <el-aside width="200px">
      <side-bar :routes="routes"></side-bar>
    </el-aside>
    <el-container class="page-content">
      <el-header class="navbar">
        <el-container class="container">
          <div class="logo">
            <img alt="Vue logo" src="@/assets/logo.svg" />
          </div>
          <ul class="nav">
            <!-- <li>
              <a href="#">111</a>
            </li>
             -->
            <li>
              <!-- <a href="#">2222</a> -->
              <el-link :underline="false" @click="clearCache">清理缓存</el-link>
            </li>
            <li class="nav-item">
              <div class="profile">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-avatar
                      :src="user.avatar_pic ? user.avatar_pic : avatar"
                    ></el-avatar>
                    <label>{{ user.name }}</label>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link :to="{ path: '/profile' }">
                        <el-dropdown-item icon="el-icon-user"
                          >个人中心</el-dropdown-item
                        >
                      </router-link>
                      <el-dropdown-item
                        icon="el-icon-switch-button"
                        @click="logout"
                        >退出</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </el-container>
      </el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in breadList" :key="item">{{
          item.meta.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <el-scrollbar> -->
        <el-main>
          <router-view></router-view>
        </el-main>
      <!-- </el-scrollbar> -->
    </el-container>
  </el-container>
</template>

<script>
import avatar from "@/assets/avatar.png";
import SideBar from "@/layout/sidebar";
import common_api from "../http/common_api";

export default {
  name: "layout",
  components: { SideBar },
  data() {
    return {
      //本地默认头像
      avatar: avatar,
      user: {
        avatar_pic: "",
      },
      routes: [],
      //breadcrumb列表
      breadList: [],
    };
  },
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      this.getBreadcrumb();
    },
    /**
     * 监听个人资料变化
     */
    getMy(data) {
      this.user = data;
    },
  },
  created() {
    this.getBreadcrumb();

    this.$http
      .get("/admin/v1/center")
      .then((res) => {
        if (res.code == 0) {
          //   this.user = res.data;
          this.$store.commit("setMy", res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    /**
     * 获取我的菜单数据
     */
    this.$http.get("/admin/v1/center/vue_route").then((res) => {
      if (res.code == 0) {
        this.routes = res.data;
      }
    });

    /**
     * 获取系统配置参数
     */
    common_api.get_settings().then((res) => {
      if (res.code == 0) {
        this.$store.commit("setSettings", res.data);
      }
    });
  },
  computed: {
    /**
     * 从状态中获取个人信息
     */
    getMy() {
      return this.$store.getters.getMy;
    },
    /**
     * 从状态中获取菜单数据
     */
    getMenus() {
      return this.$store.getters.getMenus;
    },
  },
  methods: {
    /**
     * 退出登录
     */
    logout() {},
    /**
     * 获取breadcrumb
     */
    getBreadcrumb() {
      if (
        this.$route.matched &&
        Object.keys(this.$route.matched[0].meta).length > 0
      ) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
      }
    },

    /**
     * 清理缓存
     */
    clearCache() {
      this.$confirm("您确定要清除系统所有缓存？", "温馨提示").then(() => {
        this.$http.get("admin/v1/setting/cache/flush");
      }).catch(()=>{
        
      });
    },
  },
};
</script>


<style scoped>
.el-main {
  display: flex;
  flex-direction: column;
}
</style>

