/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-11 01:50:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-20 22:13:53
 */

import http from "./axios_init";

export default {
  /**
   * 获取 menu 的参数
   * @returns
   */
  get_enums() {
    return http.get("/common/enums");
  },

  /**
   * 获取系统的配置参数
   */
  get_settings() {
    return http.get("admin/v1/common/config");
  },


  
};
