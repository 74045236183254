import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "nprogress/nprogress.css";
import "@/assets/app.css";
import App from "./App.vue";
import router from "./router/router";
import http from "./http/http";
import store from "./store/store";
import * as ElIcons from "@element-plus/icons-vue";
import config from "./plugins/in_config";
const app = createApp(App);

app.use(ElementPlus, { locale });
app.use(router);
app.use(http);
app.use(store);
app.use(config);

for (const [key, component] of Object.entries(ElIcons)) {
  app.component(key, component);
}

app.mount("#app");
