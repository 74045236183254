/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:46:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-28 12:07:23
 */

export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000"
      : "https://api.liangtingkeji.com",
  /**
   * 本地缓存token名称
   */
  TOKEN: "token",

  /**
   * 项目app key
   */
  APP_KEY: "pms_1806b4118f7",
};
