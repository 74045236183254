<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 22:13:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-09 14:34:53
-->

<template>
  <el-scrollbar>
    <el-menu
      :router="true"
      active-text-color="#1890ff"
      background-color="#304156"
      text-color="#fff">
      <el-menu-item index="home" :route="{ path: '/dashboard' }">
        <el-icon><house /></el-icon>首页
      </el-menu-item>
      <sub-menu
        v-for="item in menu"
        :key="item"
        :father="item"
        :menus="item.children"
      ></sub-menu>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubMenu from "./submenu";

export default {
  name: "SideBar",
  components: {
    SubMenu,
  },
  data() {
    return {
      menu: [],
    };
  },
  props: ["routes"],
  watch: {
    routes: {
      handler(arr) {
        this.menu = arr;
      },
    },
  },
};
</script>